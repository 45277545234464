import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import React, {useState} from 'react';
import './contact.css';

import Button from '../components/Button';
import CTA from '../components/CTA';
import fetch from '../components/fetch';
import Layout from '../components/layout';
import SEO from '../components/seo';

const formSchema = Yup.object().shape({
  company: Yup.string().max(255),
  email: Yup.string()
    .email('Invalid email!')
    .required('Email is required!'),
  message: Yup.string()
    .max(32000)
    .required(),
});

const initialValues = {
  company: '',
  email: '',
  message: '',
};

const ContactPage = () => {
  const [successMessage, setSuccessMessage] = useState();

  return (
    <Layout spacingTop={false}>
      <SEO title="Contact Us - Fleet Serverless function fastest" />
      <section className="relative mt-12 md:mt-24 px-6 lg: px-12">
        <div className="container flex flex-col md:flex-row">
          <div className="flex flex-col md:w-3/6 md:pr-12">
            <p className="text-gray mb-2">GET IN TOUCH WITH US</p>
            <h1 className="text-3xl sm:text-4xl md:text-5xl leading-tight font-semibold">
              Find out how Fleet can help your company.
            </h1>
            <p className="text-gray mt-8 leading-relaxed">
              We want to hear from you! Whether you’re a potential user with
              questions about our product or are already using Fleet, we’re
              ready to help.
            </p>
          </div>
          <div className="flex md:w-3/6 relative md:pl-12 mt-12 md:mt-0">
            <div className="hidden grid-contact sm:block w-full h-full z-0 absolute">
              <div className="grid-bg" />
            </div>
            <div className="shadow-xl bg-white px-8 py-12 w-full z-1 relative">
              <h2 className="text-3xl mb-6 mx-2 font-semibold">Let's talk</h2>
              <Formik
                initialValues={initialValues}
                validationSchema={formSchema}
                onSubmit={(values, {setSubmitting, setErrors, resetForm}) => {
                  fetch('sales-contact', {method: 'PUT', body: values})
                    .then(res => {
                      resetForm();
                      setSuccessMessage(res.message);
                      setSubmitting(false);
                    })
                    .catch(err => {
                      if (err.error) {
                        setErrors({message: err.error});
                      } else {
                        setErrors({
                          message: 'Error when making the request, try again.',
                        });
                      }
                      setSubmitting(false);
                    });
                }}
              >
                {({isSubmitting}) => (
                  <Form>
                    <div className="my-4 mx-2">
                      <Field
                        name="email"
                        className="appearance-none block w-full text-gray-700 border border-secondary rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                        type="email"
                        placeholder="Email"
                      />
                      <ErrorMessage name="email">
                        {msg => <div className="mt-2 text-red-600">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="my-4 mx-2">
                      <Field
                        name="company"
                        className="appearance-none block w-full text-gray-700 border border-secondary rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                        type="text"
                        placeholder="Company"
                      />
                      <ErrorMessage name="company">
                        {msg => <div className="mt-2 text-red-600">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="my-4 mx-2">
                      <Field
                        as="textarea"
                        name="message"
                        className="appearance-none block w-full text-gray-700 border border-secondary rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                        type="text"
                        placeholder="How can we help"
                      />
                      <ErrorMessage name="message">
                        {msg => <div className="mt-2 text-red-600">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    {successMessage && (
                      <p className="text-gray my-4 mt-8 mx-2">
                        {successMessage}
                      </p>
                    )}
                    <div className="my-4 mt-8 mx-2">
                      <Button
                        type="submit"
                        block
                        size="lg"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-16 lg:mt-48 sm:mb-40 mb-16 px-6">
        <div className="container">
          <div className="text-center">
            <h2 className="font-bold text-4xl mb-8">
              Create faster solutions with functions
            </h2>
            <p className="font-medium text-gray text-xl max-w-3xl inline-block eading-relaxed">
              Use the Fleet to build your critical services that require
              resilience, high demand, low cost and high speed with our
              technology.
            </p>
          </div>
          <ul className="mt-32 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-16">
            <li className="flex flex-col">
              <div className="rounded-md bg-black h-32 flex items-center justify-center">
                <img
                  className="select-none max-w-xl"
                  alt="JAMstack"
                  src="/images/JAMstack.svg"
                />
              </div>
              <div className="mt-6">
                <h3 className="mb-2 text-xl font-semibold">JAMstack</h3>
                <p className="text-base text-gray">
                  Enrich the JAMstack ecosystem using functions
                </p>
              </div>
            </li>
            <li className="flex flex-col">
              <div className="rounded-md bg-black h-32 flex items-center justify-center">
                <img
                  className="select-none max-w-xl"
                  alt="Microservices"
                  src="/images/microservices.svg"
                />
              </div>
              <div className="mt-6">
                <h3 className="mb-2 text-xl font-semibold">Microservices</h3>
                <p className="text-base text-gray">
                  Build your service fleet on serverless functions
                </p>
              </div>
            </li>
            <li className="flex flex-col">
              <div className="rounded-md bg-black h-32 flex items-center justify-center">
                <img
                  className="select-none max-w-xl"
                  alt="Micro APIs"
                  src="/images/apis.svg"
                />
              </div>
              <div className="mt-6">
                <h3 className="mb-2 text-xl font-semibold">APIs</h3>
                <p className="text-base text-gray">
                  Build your product's APIs on serverless functions
                </p>
              </div>
            </li>
            <li className="flex flex-col">
              <div className="rounded-md bg-black h-32 flex items-center justify-center">
                <img
                  className="select-none max-w-xl"
                  alt="Data Processing"
                  src="/images/tasks.svg"
                />
              </div>
              <div className="mt-6">
                <h3 className="mb-2 text-xl font-semibold">Tasks</h3>
                <p className="text-base text-gray">
                  Orchestrate background functions for data processing
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <CTA />
    </Layout>
  );
};

export default ContactPage;
